import { render, staticRenderFns } from "./bill1.vue?vue&type=template&id=14a57d22&scoped=true"
import script from "./bill1.vue?vue&type=script&lang=js"
export * from "./bill1.vue?vue&type=script&lang=js"
import style0 from "./bill1.vue?vue&type=style&index=0&id=14a57d22&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14a57d22",
  null
  
)

component.options.__file = "bill1.vue"
export default component.exports